import { GET } from "../request";
import { Product } from "../types";
import { productName } from "../utils";

type RateLimitResponse = {
  id: string;
  rate_limit: number;
  used_rate_limit: number;
  rate_limit_interval: number;
  company_sso_id: string;
  api_url: string;
  component_name: string;
  product: string;
};

export type RateLimit = {
  componentName: string;
  product: Product;
  productName: string;
  averageRateLimit: number;
  averageRateLimitInterval: number;
  endpoints: RateLimitEndpoint[];
};

export type RateLimitEndpoint = {
  apiUrl: string;
  rateLimit: number;
  rateLimitInterval: number;
};

export const getRateLimit = async (
  companySsoId: string
): Promise<RateLimit[]> => {
  const url = `/api/internal/mdc_api/api_usage/rate/${companySsoId}`;
  const rateLimits = await GET<RateLimitResponse[]>(url);
  const mapData: Record<string, RateLimitResponse[]> = {};
  for (const rateLimit of rateLimits) {
    const key = `${rateLimit.product}:${rateLimit.component_name}`;
    if (mapData[key]) mapData[key] = [...mapData[key], rateLimit];
    else mapData[key] = [rateLimit];
  }

  return Object.keys(mapData).map<RateLimit>((key) => {
    const endpoints = mapData[key].map<RateLimitEndpoint>((data) => ({
      apiUrl: data.api_url,
      rateLimit: data.rate_limit,
      rateLimitInterval: data.rate_limit_interval,
    }));

    const firstData = mapData[key][0];
    return {
      componentName: firstData.component_name || "Global API rate limit",
      product: firstData.product as Product,
      productName: productName(firstData.product as Product),
      endpoints,
      averageRateLimit: Math.round(
        endpoints.reduce((sum, { rateLimit }) => (sum + rateLimit), 0) / endpoints.length
      ),
      averageRateLimitInterval: Math.round(
        endpoints.reduce((sum, { rateLimitInterval }) => (sum + rateLimitInterval), 0) /
        endpoints.length
      ),
    };
  });
};
