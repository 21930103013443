import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-title" }
const _hoisted_2 = { class: "page-content" }
const _hoisted_3 = {
  key: 0,
  class: "form"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { value: "" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "description" }
const _hoisted_10 = { class: "scopes-container" }
const _hoisted_11 = {
  key: 0,
  class: "scopes-info"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 1,
  class: "form"
}
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "description" }
const _hoisted_20 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mpx_icon = _resolveComponent("mpx-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tree_check_box = _resolveComponent("tree-check-box")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("pages.applications.title")), 1),
      _createElementVNode("h1", null, [
        _createVNode(_component_router_link, { to: "/dashboard/applications" }, {
          default: _withCtx(() => [
            _createVNode(_component_mpx_icon, { variant: "arrow-left" })
          ]),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t("pages.create_hmac_auth_application.title")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.section === 'new')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.create_hmac_auth_application.form.application.label")), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.applicationName) = $event))
              }, null, 512), [
                [_vModelText, _ctx.applicationName]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.create_hmac_auth_application.form.company.label")), 1),
              _withDirectives(_createElementVNode("select", {
                class: "form-control",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCompanyId) = $event)),
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.fetchScopes && _ctx.fetchScopes(...args)))
              }, [
                _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$t(
                  "pages.create_hmac_auth_application.form.company.description"
                )), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (company) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: company.id,
                    value: company.id
                  }, _toDisplayString(company.name), 9, _hoisted_7))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.selectedCompanyId]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.create_hmac_auth_application.form.scopes.label")), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.create_hmac_auth_application.form.scopes.description")), 1),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.scopes.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (_ctx.scopes.length === 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t(
                    "pages.create_hmac_auth_application.form.scopes.info.fetching"
                  )), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t(
                    "pages.create_hmac_auth_application.form.scopes.info.empty"
                  )), 1))
                    ]))
                  : (_openBlock(), _createBlock(_component_tree_check_box, {
                      key: 1,
                      onCheck: _ctx.onCheckScopes,
                      items: _ctx.scopes
                    }, null, 8, ["onCheck", "items"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("button", {
                type: "button",
                disabled: _ctx.scopes.length === 0,
                class: "btn btn-primary field-submit",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.createHmacAuthApplication && _ctx.createHmacAuthApplication(...args)))
              }, _toDisplayString(_ctx.isSavingApplication
                ? _ctx.$t("pages.create_hmac_auth_application.form.button.saving")
                : _ctx.$t("pages.create_hmac_auth_application.form.button.create")), 9, _hoisted_15)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.section === 'created')
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.create_hmac_auth_application.form.client_id.label")), 1),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.clientId) + " ", 1),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.copyToClipboard(_ctx.clientId)))
                }, [
                  _createVNode(_component_mpx_icon, { variant: "file-copy" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.create_hmac_auth_application.form.client_secret.label")), 1),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.clientSecret) + " ", 1),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.copyToClipboard(_ctx.clientSecret)))
                }, [
                  _createVNode(_component_mpx_icon, { variant: "file-copy" })
                ])
              ]),
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t(
                "pages.create_hmac_auth_application.form.client_secret.description"
              )), 1)
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_router_link, {
                class: "btn btn-primary field-submit",
                to: `/dashboard/applications/${_ctx.applicationId}`,
                style: { textDecoration: 'none' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("pages.create_hmac_auth_application.form.button.detail")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}