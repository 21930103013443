import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["placeholder", "maxlength"]


export default /*@__PURE__*/_defineComponent({
  __name: 'InputBox',
  props: /*@__PURE__*/_mergeModels({
    rightIcon: {},
    leftIcon: {},
    placeholder: {},
    maxLength: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["change"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = _useModel<string>(__props, "modelValue");
const posClass = props.rightIcon ? "right" : props.leftIcon ? "left" : "";

const onInput = (event: Event) => {
  emit("change", (event.target as HTMLInputElement).value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(posClass))
  }, [
    (props.leftIcon || props.rightIcon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(`fa-solid ${'fa-' + (props.leftIcon || props.rightIcon)}`)
        }, null, 2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      class: "_input",
      type: "text",
      onInput: onInput,
      placeholder: props.placeholder,
      maxlength: props.maxLength || 256
    }, null, 40, _hoisted_1), [
      [_vModelText, model.value]
    ])
  ], 2))
}
}

})