import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { Product } from "../api/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
    product: {},
    size: {}
  },
  setup(__props: any) {

const fileMap: Record<Product, string> = {
  talenta: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.87944 21.7974L18.702 17.2408C21.2086 16.485 22.999 14.5276 22.999 11.9976C22.999 9.49356 21.2283 7.55207 18.7794 6.77316L4.87944 2.19637C3.53208 1.74633 2.05574 2.06078 1.34413 3.23346C0.632516 4.40471 1.07708 5.75193 2.16802 6.66209L8.56951 11.9976L2.16802 17.3331C1.07708 18.2375 0.632516 19.5905 1.34413 20.7617C2.05574 21.933 3.52297 22.2604 4.87944 21.7988V21.7974Z" fill="#F22929"/>
    </svg>`,
  jurnal: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11.9966 1C14.5266 1 16.484 2.79041 17.2398 5.29699L21.7979 19.1196C22.2594 20.476 21.932 21.9433 20.7608 22.6549C19.5895 23.3665 18.2365 22.9219 17.3321 21.831L11.9966 15.4295L6.66112 21.831C5.75095 22.9219 4.40373 23.3665 3.23249 22.6549C2.0598 21.9433 1.74536 20.4669 2.19539 19.1196L6.77218 5.21961C7.55109 2.77069 9.49258 1 11.9966 1Z" fill="#40C3FF"/>
    </svg>`,
  qontak_chat: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.3135 17.6229H12.0005C8.8993 17.6229 6.37613 15.0997 6.37613 11.9985C6.37613 8.89724 8.8993 6.37407 12.0005 6.37407C15.1018 6.37407 17.625 8.89724 17.625 11.9985V12.5629C17.625 14.0452 18.8312 15.2499 20.312 15.2499C21.7928 15.2499 22.999 14.0437 22.999 12.5629V11.9348C22.9808 9.00952 21.8292 6.26331 19.7552 4.19986C17.6796 2.13641 14.9258 1 11.999 1C5.93309 1 0.999023 5.93407 0.999023 12C0.999023 18.0659 5.93309 23 11.999 23H20.312C21.7943 23 22.999 21.7938 22.999 20.313C22.999 18.8321 21.7928 17.6259 20.312 17.6259L20.3135 17.6229Z" fill="#2979FF"/>
    </svg>`,
  qontak_crm: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.3135 17.6229H12.0005C8.8993 17.6229 6.37613 15.0997 6.37613 11.9985C6.37613 8.89724 8.8993 6.37407 12.0005 6.37407C15.1018 6.37407 17.625 8.89724 17.625 11.9985V12.5629C17.625 14.0452 18.8312 15.2499 20.312 15.2499C21.7928 15.2499 22.999 14.0437 22.999 12.5629V11.9348C22.9808 9.00952 21.8292 6.26331 19.7552 4.19986C17.6796 2.13641 14.9258 1 11.999 1C5.93309 1 0.999023 5.93407 0.999023 12C0.999023 18.0659 5.93309 23 11.999 23H20.312C21.7943 23 22.999 21.7938 22.999 20.313C22.999 18.8321 21.7928 17.6259 20.312 17.6259L20.3135 17.6229Z" fill="#2979FF"/>
    </svg>`,
  klikpajak: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M10.4902 23C12.5111 23 13.8899 21.4802 14.6785 19.7596L21.315 3.91869C21.7384 2.86775 21.4236 1.78986 20.4871 1.25361C19.4923 0.769907 18.4365 0.978748 17.7484 1.87474L10.4902 12.1214L6.22874 7.29517C5.47551 6.42343 4.42642 6.13779 3.48997 6.67404C2.55489 7.21029 2.29024 8.27201 2.6621 9.33912L6.31695 19.8189C7.03354 21.6243 8.48978 23 10.4902 23Z" fill="#FF9100"/>
    </svg>`,
  mekari_sign: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.96833 23H18.0347C18.7076 23 19.2531 22.4486 19.2531 21.7684C19.2531 21.0883 18.7076 20.5369 18.0347 20.5369H4.96833C4.29547 20.5369 3.75 21.0883 3.75 21.7684C3.75 22.4486 4.29547 23 4.96833 23Z" fill="#00C853"/>
      <path d="M14.4879 16.2151C13.8213 17.6108 12.6571 18.8423 10.9491 18.8423C9.25951 18.8423 8.02929 17.7269 7.42342 16.2618L5.01183 11.1115C4.69768 10.2468 4.92075 9.38489 5.71142 8.94991C6.50208 8.51493 7.38778 8.74576 8.02533 9.45294L10.4699 13.1009L17.0803 1.70871C17.6611 0.98286 18.5534 0.813405 19.3943 1.20569C20.1849 1.64067 20.4516 2.51463 20.0938 3.36724L14.4879 16.2151Z" fill="#00C853"/>
    </svg>`,
};

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "_icon",
    style: _normalizeStyle({ height: props.size, width: props.size }),
    innerHTML: fileMap[props.product]
  }, null, 12, _hoisted_1))
}
}

})