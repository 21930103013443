import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "_content" }
const _hoisted_2 = { class: "_header" }
const _hoisted_3 = {
  key: 0,
  class: "_error"
}
const _hoisted_4 = {
  key: 1,
  class: "_error"
}
const _hoisted_5 = {
  key: 0,
  class: "fas fa-sort-up sort-icon"
}
const _hoisted_6 = {
  key: 1,
  class: "fas fa-sort-down sort-icon"
}
const _hoisted_7 = {
  key: 2,
  class: "fas fa-sort disabled sort-icon"
}
const _hoisted_8 = {
  key: 0,
  class: "fas fa-sort-up sort-icon"
}
const _hoisted_9 = {
  key: 1,
  class: "fas fa-sort-down sort-icon"
}
const _hoisted_10 = {
  key: 2,
  class: "fas fa-sort disabled sort-icon"
}
const _hoisted_11 = {
  key: 0,
  class: "fas fa-sort-up sort-icon"
}
const _hoisted_12 = {
  key: 1,
  class: "fas fa-sort-down sort-icon"
}
const _hoisted_13 = {
  key: 2,
  class: "fas fa-sort disabled sort-icon"
}
const _hoisted_14 = { class: "_product" }
const _hoisted_15 = { class: "_component" }
const _hoisted_16 = ["onClick"]

import { RateLimitEndpoint, getRateLimit, RateLimit } from "@/api/api_usage";
import Icon from "@/components/Icon.vue";
import InputBox from "@/components/InputBox.vue";
import TableSection from "@/components/TableSection.vue";
import { onBeforeMount, ref, watch } from "vue";
import { PRODUCTS } from "@/constants";
import RateLimitListModal, {
  Row,
} from "@/components/api_usage/RateLimitListModal.vue";
import { useToast, TYPE as ToastType, POSITION } from "vue-toastification";
import DropdownCheck from "@/components/DropdownCheck.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RateLimitTab',
  props: {
    companySsoId: {}
  },
  setup(__props: any) {

const props = __props;

const toast = useToast();
const isEndpointListModalOpen = ref<boolean>(false);

const selectedComponents = ref<Row[]>([]);
const originSelectedComponents = ref<Row[]>([]);
const components = ref<RateLimit[]>([]);
const originComponents = ref<RateLimit[]>([]);
const selectedProducts = ref<string[]>([]);
const totalSelectedComponents = ref<number>(0);
const totalComponents = ref<number>(0);
const size = ref<number>(10);
const page = ref<number>(1);
const sizeModal = ref<number>(10);
const pageModal = ref<number>(1);
const sortBy = ref<string>("productName");
const orderBy = ref<string>("asc");
const statusCode = ref<number>(0);

const refreshData = async (keyword = '') => {
  if (props.companySsoId === "") return;

  try {
    components.value = await getRateLimit(props.companySsoId);
    originComponents.value = [...components.value];
    totalComponents.value = components.value.length;
    
    let filteredData = originComponents.value;
    if (selectedProducts.value.length > 0) {
      filteredData = filteredData.filter(({ product }) =>
        selectedProducts.value.includes(product)
      );
    }

    if (keyword.length >= 3) {
      filteredData = filteredData.filter(({ componentName }) =>
        new RegExp(keyword, 'i').test(componentName)
      );
    }

    filteredData.sort((a, b) => {
      if (a[sortBy.value] < b[sortBy.value]) return orderBy.value === 'asc' ? -1 : 1;
      if (a[sortBy.value] > b[sortBy.value]) return orderBy.value === 'asc' ? 1 : -1;
      return 0;
    });

    const start = (page.value - 1) * size.value;
    const end = start + size.value;
    components.value = filteredData.slice(start, end);

    totalComponents.value = filteredData.length;
    statusCode.value = 200;
  } catch (err) {
    const match = err.message.match(/return (\d+)/);
    statusCode.value = match[1];

    if (statusCode.value != 404) {
      toast(
        "Failed to load the quota data. Please try again by refreshing the page",
        {
          type: ToastType.ERROR,
          toastClassName: "mdc-toast",
          hideProgressBar: true,
          closeButton: false,
          position: POSITION.TOP_CENTER,
          timeout: 3000,
        }
      );
    }
  }
};

const refreshModalData = async (keyword = '') => {
  let filteredData = originSelectedComponents.value;
  filteredData.sort((a, b) => {
    if (a['url'] < b['url']) return 1;
    return 0;
  });

  if (keyword.length >= 3) {
    filteredData = filteredData.filter(({ url }) =>
      new RegExp(keyword, 'i').test(url)
    );
  }

  const start = (pageModal.value - 1) * sizeModal.value;
  const end = start + sizeModal.value;
  selectedComponents.value = filteredData.slice(start, end);
  totalSelectedComponents.value = filteredData.length;
};

const onSelectComponent = (endpoints: RateLimitEndpoint[]) => {
  pageModal.value = 1;
  sizeModal.value = 10;
  isEndpointListModalOpen.value = true;
  selectedComponents.value = endpoints.map<Row>((data) => ({
    url: data.apiUrl,
    rateLimit: data.rateLimit,
    rateLimitInterval: data.rateLimitInterval,
  }));
  originSelectedComponents.value = [...selectedComponents.value];
  totalSelectedComponents.value = selectedComponents.value.length;
  refreshModalData();
};

const onSearch = (keyword: string) => {
  refreshData(keyword);
};

const onSearchModal = (keyword: string) => {
  refreshModalData(keyword);
};

onBeforeMount(async () => {
  refreshData();
});

watch([size, page, props], () => {
  refreshData();
});

watch(selectedProducts, () => {
  refreshData();
});

watch([sizeModal, pageModal], () => {
  refreshModalData();
});

const onSort = (key) => {
  sortBy.value = key;
  orderBy.value = orderBy.value === 'asc' ? 'desc' : 'asc';
  refreshData();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(DropdownCheck, {
          modelValue: selectedProducts.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedProducts).value = $event)),
          placeholder: "All products",
          "options-title": "P R O D U C T",
          options: _unref(PRODUCTS).map(({ id, name }) => ({ id, text: name }))
        }, null, 8, ["modelValue", "options"]),
        _createElementVNode("button", {
          class: "_btn",
          onClick: refreshData
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("i", { class: "fa-solid fa-arrows-rotate" }, null, -1),
          _createElementVNode("p", null, "Refresh", -1)
        ]))
      ]),
      _createVNode(InputBox, {
        "left-icon": "magnifying-glass",
        onChange: onSearch,
        placeholder: "Search component name",
        "max-length": "50"
      })
    ]),
    _createVNode(TableSection, {
      "total-data": totalComponents.value,
      onChangeSize: _cache[4] || (_cache[4] = (newSize) => (size.value = newSize)),
      onNext: _cache[5] || (_cache[5] = (newPage) => (page.value = newPage)),
      onPrev: _cache[6] || (_cache[6] = (newPage) => (page.value = newPage))
    }, {
      error: _withCtx(() => [
        (statusCode.value == 404)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[12] || (_cache[12] = [
              _createElementVNode("img", {
                src: "https://cdn.mekari.design/illustration/blank-slate/NoData_PB_M_01.png",
                alt: "no-data"
              }, null, -1),
              _createElementVNode("h1", null, "No rate limit details yet", -1),
              _createElementVNode("p", null, [
                _createTextVNode(" Once you create and app from "),
                _createElementVNode("strong", null, "Application"),
                _createTextVNode(" menu, your rate limit details will appear here. ")
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[13] || (_cache[13] = [
              _createElementVNode("img", {
                src: "https://cdn.mekari.design/illustration/blank-slate/NotResultFound_PB_M_01.png",
                alt: "no-data"
              }, null, -1),
              _createElementVNode("h1", null, "No component found", -1),
              _createElementVNode("p", null, "Recheck the keywords you have typed and try searching again.", -1)
            ])))
      ]),
      header: _withCtx(() => [
        _createElementVNode("th", {
          class: "sortable-column",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (onSort('productName')))
        }, [
          _cache[14] || (_cache[14] = _createTextVNode(" Product ")),
          _createElementVNode("span", null, [
            (sortBy.value === 'productName' && orderBy.value === 'asc')
              ? (_openBlock(), _createElementBlock("i", _hoisted_5))
              : (sortBy.value === 'productName' && orderBy.value === 'desc')
                ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                : (_openBlock(), _createElementBlock("i", _hoisted_7))
          ])
        ]),
        _createElementVNode("th", {
          class: "sortable-column",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (onSort('componentName')))
        }, [
          _cache[15] || (_cache[15] = _createTextVNode(" Component name ")),
          _createElementVNode("span", null, [
            (sortBy.value === 'componentName' && orderBy.value === 'asc')
              ? (_openBlock(), _createElementBlock("i", _hoisted_8))
              : (sortBy.value === 'componentName' && orderBy.value === 'desc')
                ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                : (_openBlock(), _createElementBlock("i", _hoisted_10))
          ])
        ]),
        _createElementVNode("th", {
          class: "sortable-column",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (onSort('averageRateLimit')))
        }, [
          _cache[16] || (_cache[16] = _createTextVNode(" Rate limit ")),
          _createElementVNode("span", null, [
            (sortBy.value === 'averageRateLimit' && orderBy.value === 'asc')
              ? (_openBlock(), _createElementBlock("i", _hoisted_11))
              : (sortBy.value === 'averageRateLimit' && orderBy.value === 'desc')
                ? (_openBlock(), _createElementBlock("i", _hoisted_12))
                : (_openBlock(), _createElementBlock("i", _hoisted_13))
          ])
        ])
      ]),
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(components.value, (row, i) => {
          return (_openBlock(), _createElementBlock("tr", { key: i }, [
            _createElementVNode("td", _hoisted_14, [
              _createElementVNode("div", null, [
                _createVNode(Icon, {
                  product: row.product,
                  size: "1.5rem"
                }, null, 8, ["product"])
              ]),
              _createElementVNode("p", null, _toDisplayString(row.productName), 1)
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("p", null, _toDisplayString(row.componentName), 1),
                _createElementVNode("a", {
                  onClick: ($event: any) => (onSelectComponent(row.endpoints)),
                  class: _normalizeClass(row.endpoints.length > 1 ? '_link' : '_non-link')
                }, _toDisplayString(row.endpoints.length > 1
                    ? "View endpoint URL"
                    : row.endpoints[0].apiUrl == "*"
                    ? "Applied to all endpoints"
                    : row.endpoints[0].apiUrl), 11, _hoisted_16)
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("p", null, _toDisplayString(row.averageRateLimit) + "/" + _toDisplayString(row.averageRateLimitInterval == 60
                  ? "minute"
                  : `${row.averageRateLimitInterval} seconds`), 1)
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["total-data"]),
    _createVNode(RateLimitListModal, {
      "is-modal-open": isEndpointListModalOpen.value,
      data: selectedComponents.value,
      "total-data": totalSelectedComponents.value,
      onSearch: onSearchModal,
      onClose: _cache[7] || (_cache[7] = ($event: any) => (isEndpointListModalOpen.value = false)),
      onChangeSize: _cache[8] || (_cache[8] = (newSize) => (sizeModal.value = newSize)),
      onNext: _cache[9] || (_cache[9] = (newPage) => (pageModal.value = newPage)),
      onPrev: _cache[10] || (_cache[10] = (newPage) => (pageModal.value = newPage))
    }, null, 8, ["is-modal-open", "data", "total-data"])
  ]))
}
}

})