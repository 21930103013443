import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "_head" }

import InputBox from "../InputBox.vue";
import Modal from "../Modal.vue";
import TableSection, { Events as TableEvents } from "../TableSection.vue";

export type Row = {
  url: string;
  rateLimit: number;
  rateLimitInterval: number;
};

export type Events = TableEvents & {
  search: [value: string];
  close: [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RateLimitListModal',
  props: {
    isModalOpen: { type: Boolean },
    data: {},
    totalData: {}
  },
  emits: ["change-size", "next", "prev", "page", "search", "close"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    header: "Endpoint URL",
    "is-modal-open": props.isModalOpen,
    width: "60vw",
    onClose: _cache[4] || (_cache[4] = ($event: any) => (emit('close')))
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(InputBox, {
          "left-icon": "magnifying-glass",
          onChange: _cache[0] || (_cache[0] = (value) => emit('search', value)),
          placeholder: "Search endpoint",
          "max-length": "50"
        })
      ]),
      _createVNode(TableSection, {
        height: "60vh",
        "total-data": props.totalData,
        onChangeSize: _cache[1] || (_cache[1] = (value) => emit('change-size', value)),
        onNext: _cache[2] || (_cache[2] = (value) => emit('next', value)),
        onPrev: _cache[3] || (_cache[3] = (value) => emit('prev', value))
      }, {
        error: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("div", { class: "_error" }, [
            _createElementVNode("img", {
              src: "https://cdn.mekari.design/illustration/blank-slate/NotResultFound_PB_M_01.png",
              alt: "no-data"
            }),
            _createElementVNode("h1", null, "No component found"),
            _createElementVNode("p", null, "Recheck the keywords you have typed and try searching again.")
          ], -1)
        ])),
        header: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("th", null, "Endpoint", -1),
          _createElementVNode("th", null, "Rate limit", -1),
          _createElementVNode("th", null, "Interval", -1)
        ])),
        body: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data, (row, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, [
                _createElementVNode("p", null, _toDisplayString(row.url), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("p", null, _toDisplayString(row.rateLimit), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("p", null, _toDisplayString(row.rateLimitInterval) + " seconds", 1)
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["total-data"]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "_footer" }, [
        _createElementVNode("p", null, "Need guidance on your endpoint?"),
        _createElementVNode("a", {
          class: "_link",
          href: "/docs/kb/product-api"
        }, "Visit API Docs")
      ], -1))
    ]),
    _: 1
  }, 8, ["is-modal-open"]))
}
}

})