import type { Product } from "./types";

export const productName = (product: Product): string => {
  const nameMap: Record<Product, string> = {
    talenta: "Mekari Talenta",
    jurnal: "Mekari Jurnal",
    qontak_crm: "Mekari Qontak CRM",
    qontak_chat: "Mekari Qontak Chat",
    klikpajak: "Mekari Klikpajak",
    mekari_sign: "Mekari Sign",
  };

  return nameMap[product] || "UNKNOWN";
};
