/**
 * Popup vuex module
 */
import type { Module, ActionTree, MutationTree } from "vuex";
import type { RootState } from "@/interfaces";
import { SCOPE_SET_SCOPES, SCOPE_FETCH_SCOPES } from "@/store-types";
import type { GetScopesRequest, ScopeState } from "@/interfaces/scope";
import { getScopes as hmacGetScopes, Scope } from "@/api/hmac_application";

/**
 * Initial state
 */
const state: ScopeState = {
  scopes: [],
};

/**
 * Module mutation
 */
const mutations: MutationTree<ScopeState> = {
  [SCOPE_SET_SCOPES](state: ScopeState, scopes: Array<Scope>) {
    state.scopes = scopes;
  },
};

/**
 * Module action
 */
const actions: ActionTree<ScopeState, RootState> = {
  async [SCOPE_FETCH_SCOPES]({ commit, state }, request: GetScopesRequest) {
    for await (const scopes of hmacGetScopes(request.companyId)) {
      commit(SCOPE_SET_SCOPES, state.scopes.concat(scopes));
    }
  },
};

export const scope: Module<ScopeState, RootState> = {
  state,
  mutations,
  actions,
};
