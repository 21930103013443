import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "_progress-bar" }
const _hoisted_2 = { class: "_loading" }

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressBar',
  props: {
    progress: {}
  },
  setup(__props: any) {

const props = __props;

const color = computed(() => {
  return props.progress < 50
    ? "_green"
    : props.progress < 99
    ? "_yellow"
    : "_red";
});

const configuration = computed(() => {
  return props.progress > 100
    ? {
        label: "Unlimited",
        width: "0%",
      }
    : {
        label: `${Math.floor(props.progress)}%`,
        width: props.progress + "%",
      };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(configuration.value.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(color.value),
        style: _normalizeStyle({ width: configuration.value.width })
      }, null, 6)
    ])
  ]))
}
}

})