import { GET } from "../request";

type CompaniesResponse = {
  id: string;
  type: string;
  attributes: {
    name: string;
    company_size: string;
    platforms: string[];
  };
};

export type Company = {
  id: string;
  name: string;
};

export const getCompanies = async (): Promise<Company[]> => {
  const url = "/api/internal/users/me/companies";
  const response = sessionStorage.getItem(url);
  if (response) return JSON.parse(response) as Company[];

  const companiesResponse = await GET<CompaniesResponse[]>(url);
  const companies = companiesResponse.map((data) => ({
    id: data.id,
    name: data.attributes.name,
  }));
  sessionStorage.setItem(url, JSON.stringify(companies));
  return companies;
};
