import { GET } from "../request";
import { Product } from "../types";
import { productName } from "../utils";

type MonthlyUsageResponse = {
  product: string;
  component_name: string;
  used_quota: number;
  quota: number;
  api_url: string;
};

export type MonthlyUsage = {
  componentName: string;
  product: Product;
  productName: string;
  averageUsedQuota: number;
  averageQuota: number;
  totalQuota: number;
  totalUsedQuota: number;
  endpoints: MonthlyUsageEndpoint[];
};

export type MonthlyUsageEndpoint = {
  apiUrl: string;
  usedQuota: number;
  quota: number;
};

export const getMonthlyUsage = async (
  companySsoId: string
): Promise<MonthlyUsage[]> => {
  const url = `/api/internal/mdc_api/api_usage/monthly/${companySsoId}`;
  const monthlyUsages = await GET<MonthlyUsageResponse[]>(url);
  const mapData: Record<string, MonthlyUsageResponse[]> = {};
  for (const MonthlyUsage of monthlyUsages) {
    const key = `${MonthlyUsage.product}:${MonthlyUsage.component_name}`;
    if (mapData[key]) mapData[key] = [...mapData[key], MonthlyUsage];
    else mapData[key] = [MonthlyUsage];
  }

  return Object.keys(mapData).map<MonthlyUsage>((key) => {
    const endpoints = mapData[key]
      .map<MonthlyUsageEndpoint>((data) => ({
        apiUrl: data.api_url,
        usedQuota: data.used_quota,
        quota: data.quota,
      }));

    const firstData = mapData[key][0];
    return {
      componentName: firstData.component_name || "Global API Monthly Limit",
      product: firstData.product as Product,
      productName: productName(firstData.product as Product),
      endpoints,
      averageUsedQuota: Math.round(
        endpoints.reduce((sum, { usedQuota }) => (sum + usedQuota), 0) / endpoints.length
      ),
      averageQuota: Math.round(
        endpoints.reduce((sum, { quota }) => (sum + quota), 0) / endpoints.length
      ),
      totalQuota: endpoints.reduce((sum, { quota }) => (sum + quota), 0),
      totalUsedQuota: endpoints.reduce((sum, { usedQuota }) => (sum + usedQuota), 0),
    };
  });
};
