import { GET } from "../request";

type ScopeResponse = {
  id: string;
  type: string;
  attributes: {
    name: string;
    parent_id: string;
  };
};

export type Scope = {
  id: string;
  name: string;
  parentId?: string;
};

export const getScopes = async function* (
  companySsoId: string
): AsyncGenerator<Scope[]> {
  const MAX_LOOP = 100;
  let page = 1;

  while (page <= MAX_LOOP) {
    const url = `/api/internal/authorized_scopes?page=${page}&company_id=${companySsoId}&auth_type=hmac_auth`;
    const key = `${companySsoId}:${page}`;
    if (sessionStorage.getItem(`${key}:END`)) return;
    const cachedScope = sessionStorage.getItem(key);
    if (cachedScope) {
      yield JSON.parse(cachedScope);
    } else {
      try {
        const scopes = await GET<ScopeResponse[]>(url);
        if (scopes.length === 0) break;
        const newScopes = scopes.map<Scope>(({ id, attributes }) => ({
          id,
          name: attributes.name,
          parentId: attributes.parent_id,
        }));
        sessionStorage.setItem(key, JSON.stringify(newScopes));
        yield newScopes;
      } catch (err) {
        console.error("Failed get scope:", err);
      }
    }
    page++;
  }

  sessionStorage.setItem(`${companySsoId}:${page}:END`, "true");
};
