import { ROUTES } from "@/constants/routes";
import NProgress from "nprogress";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { FETCH_CURRENT_USER } from "@/store-types";
import { getFlagValue } from "@/utils/flagsmith";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "LandingPage",
    component: () =>
      import(/* webpackChunkName: "landing-page" */ "../views/LandingPage.vue"),
    meta: {
      title: "Mekari Developer",
    },
  },
  // {
  //   path: "/users/sign_up",
  //   name: "SignUpPage",
  //   component: () =>
  //     import(/* webpackChunkName: "signup" */ "../views/users/SignUp.vue"),
  //   meta: {
  //     title: "Sign Up - Mekari Developer",
  //   },
  // },
  {
    path: "/dashboard",
    redirect: { name: "ApplicationIndex" },
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/dashboard/Index.vue"
      ),
    meta: {
      title: "Dashboard - Mekari Developer",
      requiresAuth: true,
    },
    children: [
      {
        path: "applications",
        name: "ApplicationIndex",
        component: () =>
          import(
            /* webpackChunkName: "application-index" */ "../views/dashboard/applications/Index.vue"
          ),
        meta: {
          title: "Applications - Mekari Developer",
          requiresAuth: true,
        },
      },
      {
        path: "applications/:uuid",
        name: "ApplicationShow",
        redirect: { name: "ApplicationShowDetail" },
        component: () =>
          import(
            /* webpackChunkName: "application-show" */ "../views/dashboard/applications/Show.vue"
          ),
        props: true,
        meta: {
          title: "Application - Mekari Developer",
          requiresAuth: true,
        },
        children: [
          {
            path: "detail",
            name: "ApplicationShowDetail",
            component: () =>
              import(
                /* webpackChunkName: "application-show-detail" */ "../views/dashboard/applications/show/Detail.vue"
              ),
            meta: {
              title: "Application - Mekari Developer",
              requiresAuth: true,
            },
          },
          {
            path: "credentials",
            name: "ApplicationShowCredentials",
            component: () =>
              import(
                /* webpackChunkName: "application-show-credentials" */ "../views/dashboard/applications/show/Credentials.vue"
              ),
            meta: {
              title: "Application - Mekari Developer",
              requiresAuth: true,
            },
          },
          {
            path: "advanced",
            name: "ApplicationShowAdvanced",
            component: () =>
              import(
                /* webpackChunkName: "application-show-advanced" */ "../views/dashboard/applications/show/Advanced.vue"
              ),
            meta: {
              title: "Application - Mekari Developer",
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "create_application/hmac_auth",
        name: "CreateHmacAuthApplication",
        component: () =>
          import(
            /* webpackChunkName: "application-create" */ "../views/dashboard/applications/CreateHmacAuthApplication.vue"
          ),
        props: true,
        meta: {
          title: "Create HMAC Application - Mekari Developer",
          requiresAuth: true,
        },
      },
      {
        path: ROUTES.HMAC_VALIDATOR,
        name: "HmacValidator",
        component: () =>
          import(
            /* webpackChunkName: "hmac-validator" */ "../views/dashboard/applications/HmacValidator.vue"
          ),
        props: true,
        meta: {
          title: "HMAC Validator - Mekari Developer",
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/activity_logs",
        name: "ActivityLogs",
        component: () =>
          import(
            /* webpackChunkName: "application-index" */ "../views/dashboard/activity_log/Index.vue"
          ),
        meta: {
          title: "Activity Logs - Mekari Developer",
          requiresAuth: true,
          flag: "enable_activity_log",
        },
      },
      {
        path: "create_application/hmac_auth",
        name: "CreateHmacAuthApplication",
        component: () =>
          import(
            /* webpackChunkName: "application-create" */ "../views/dashboard/applications/CreateHmacAuthApplication.vue"
          ),
        props: true,
        meta: {
          title: "Create HMAC Application - Mekari Developer",
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard/activity_logs",
        name: "ActivityLogs",
        component: () =>
          import(
            /* webpackChunkName: "application-index" */ "../views/dashboard/activity_log/Index.vue"
          ),
        meta: {
          title: "Activity Logs - Mekari Developer",
          requiresAuth: true,
          flag: "enable_activity_log",
        },
      },
      {
        path: "/dashboard/api_usage",
        redirect: { name: "MonthlyUsage" },
        name: "APIUsage",
        component: () =>
          import(
            /* webpackChunkName: "api-usage" */ "../views/dashboard/ApiUsage.vue"
          ),
        meta: {
          title: "API Usage - Mekari Developer",
          requiresAuth: false,
          flag: "enable_api_usage",
        },
        children: [
          {
            path: "rate_limit",
            name: "RateLimit",
            component: () =>
              import(
                /* webpackChunkName: "api-usage" */ "../views/dashboard/api_usage/RateLimitTab.vue"
              ),
            meta: {
              title: "API Usage - Mekari Developer",
              requiresAuth: true,
            },
          },
          {
            path: "monthly_usage",
            name: "MonthlyUsage",
            component: () =>
              import(
                /* webpackChunkName: "monthly-usage" */ "../views/dashboard/api_usage/MonthlyUsageTab.vue"
              ),
            meta: {
              title: "API Usage - Mekari Developer",
              requiresAuth: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/access_grant/approved",
    name: "AccessGrantApproved",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "../views/access_grant/Approved.vue"
      ),
    props: true,
    meta: {
      title: "Grant Permission Approved - Mekari Developer",
      requiresAuth: true,
    },
  },
  {
    path: "/access_grant/:uuid",
    name: "AccessGrantPermission",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "../views/access_grant/Permission.vue"
      ),
    props: true,
    meta: {
      title: "Grant Permission - Mekari Developer",
      requiresAuth: true,
    },
  },
  {
    path: "/forbidden",
    name: "403",
    component: () =>
      import(/* webpackChunkName: "403" */ "../views/errors/Forbidden.vue"),
    meta: {
      title: "Forbidden - Mekari Developer",
    },
  },
  {
    path: "/internal_server_error",
    name: "5xx",
    component: () =>
      import(/* webpackChunkName: "5xx" */ "../views/errors/ServerError.vue"),
    meta: {
      title: "Internal Server Error - Mekari Developer",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/errors/NotFound.vue"),
    meta: {
      title: "Page Not Found - Mekari Developer",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(async (to) => {
  window.document.title = (to.meta.title || "Mekari Developer") as string;

  if (to.meta.requiresAuth) {
    NProgress.start();

    await store.dispatch(FETCH_CURRENT_USER);

    if (redirect_if(store.state.user.id === "")) return

    NProgress.done();
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.flag) {
    const isEnabled = getFlagValue(to.meta.flag as string);
    if (!isEnabled) {
      return next({ name: "Dashboard" });
    }
  }

  await store.dispatch(FETCH_CURRENT_USER);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (redirect_if(requiresAuth && store.state.user.id === "")) return

  next();
});

const redirect_if = (truthy: boolean): boolean => {
  const redirect = sessionStorage.getItem("redirect");

  if (truthy && !redirect) {
    sessionStorage.setItem("redirect", window.location.href);
    window.location.href = "/users/sign_in";
    return true;
  }

  if (redirect) {
    window.location.href = redirect;
    sessionStorage.removeItem("redirect");
    return true;
  }

  return false
}

export default router;
