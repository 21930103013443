
import { defineComponent } from "vue";
import {
  COMPANY_FETCH_COMPANIES,
  APPLICATION_CREATE_HMAC_AUTH,
  SCOPE_FETCH_SCOPES,
} from "@/store-types";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import { Company, CompanyState } from "@/interfaces/company";
import { Scope } from "@/interfaces/scope";
import TreeCheckBox, {
  Item as TreeCheckBoxItem,
} from "@/components/TreeCheckBox.vue";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/applications";
import { mixpanelInstance } from "@/utils/mixpanel";

type Section = "new" | "created";

interface Data {
  section: Section;
  applicationName: string;
  selectedCompanyId: string;
  selectedScopeIds: Set<string>;
  isSavingApplication: boolean;
  isFetchingCompanies: boolean;
  isFetchingScopes: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
    TreeCheckBox,
  },
  data(): Data {
    return {
      section: "new",
      isSavingApplication: false,
      isFetchingCompanies: false,
      isFetchingScopes: false,
      applicationName: "",
      selectedCompanyId: "",
      selectedScopeIds: new Set(),
    };
  },
  mounted() {
    this.fetchCompanies();
    // this.selectedCompanyId = '9c01354c-6b99-4563-80b1-3ad330ea7002';
    // this.fetchScopes();
  },
  computed: {
    companies(): Array<Company> {
      return this.$store.state.company.companies;
    },
    scopes(): Array<TreeCheckBoxItem> {
      const storeScopes = this.$store.state.scope.scopes;

      const parentScopeIds: Set<string | undefined> = new Set(
        storeScopes.map((scope: Scope) => scope.parentId)
      );

      const isAnyChildScopeSelected =
        Array.from(this.selectedScopeIds).filter(
          (id: string) => !parentScopeIds.has(id)
        ).length > 0;

      const scopes = storeScopes.map((scope: Scope) => ({
        ...scope,
        isChecked:
          this.selectedScopeIds.has(scope.id) && isAnyChildScopeSelected,
      }));

      return scopes;
    },
    applicationId(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.id;
    },
    clientId(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientId;
    },
    clientSecret(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientSecret;
    },
  },
  methods: {
    async fetchCompanies() {
      if (this.isFetchingCompanies) {
        return;
      }

      this.isFetchingCompanies = true;

      try {
        await this.$store.dispatch(COMPANY_FETCH_COMPANIES);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isFetchingCompanies = false;
      }
    },

    async fetchScopes() {
      this.$store.state.scope.scopes = [];

      if (this.isFetchingScopes || !this.selectedCompanyId) {
        return;
      }

      this.isFetchingScopes = this.$store.state.scope.scopes.length === 0;

      try {
        await this.$store.dispatch(SCOPE_FETCH_SCOPES, {
          companyId: this.selectedCompanyId,
          authType: "hmac_auth",
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isFetchingScopes = false;
      }
    },

    async createHmacAuthApplication() {
      if (this.isSavingApplication) {
        return;
      }

      this.isSavingApplication = true;

      const parentScopeIds: Set<string | undefined> = new Set(
        this.scopes.map((scope: Scope) => scope.parentId)
      );
      const scopes: Array<string> = this.scopes
        .filter(
          (scope: Scope) =>
            !parentScopeIds.has(scope.id) && this.selectedScopeIds.has(scope.id)
        )
        .map((scope: Scope) => scope.name);

      const metadata = {
        CompanySSOID: this.selectedCompanyId,
      };
      const dataEvent = {
        Remarks: "Success",
        Reason: "Success create HMAC Auth application",
      };
      try {
        const companyName =
          this.companies.find(({ id }) => id == this.selectedCompanyId)?.name ||
          this.selectedCompanyId;

        // checking scopes is empty or not
        if (scopes.length === 0) {
          this.$store.dispatch(SHOW_TOAST, {
            message: "Invalid scopes type",
            type: ToastType.ERROR,
          });
          return;
        }

        // checking application name is empty or not
        if (!this.applicationName) {
          this.$store.dispatch(SHOW_TOAST, {
            message: "Application name should be filled",
            type: ToastType.ERROR,
          });
          return;
        }

        // checking Application name length should between 4 to 32
        if (
          this.applicationName.length < 4 ||
          this.applicationName.length > 32
        ) {
          this.$store.dispatch(SHOW_TOAST, {
            message: "Application name length should between 4 to 32",
            type: ToastType.ERROR,
          });
          return;
        }

        await this.$store.dispatch(APPLICATION_CREATE_HMAC_AUTH, {
          scopes,
          name: this.applicationName,
          companyId: this.selectedCompanyId,
          companyName,
          pageName: "Applications",
        });

        this.section = "created";
        mixpanelInstance.setMetadata(metadata);
        emit(event.SubmitCreateApplication, dataEvent);
      } catch (err) {
        dataEvent.Remarks = "Failed";
        dataEvent.Reason = (err as any).response
          ? (err as any).response.data.message
          : "Something went wrong. Try again";
        mixpanelInstance.setMetadata(metadata);
        emit(event.SubmitCreateApplication, dataEvent);
        this.handleError(err);
      } finally {
        this.isSavingApplication = false;
      }
    },

    async copyToClipboard(text: string | any) {
      await navigator.clipboard.writeText(text);
    },

    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";

      this.$store.dispatch(SHOW_TOAST, {
        message: message,
        type: ToastType.ERROR,
      });
    },

    onCheckScopes(selectedScopeIds: Array<string>) {
      this.selectedScopeIds = new Set(selectedScopeIds);
    },
  },
});
