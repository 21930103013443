import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "_page" }
const _hoisted_2 = { class: "_page-header" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "_page-tab" }
const _hoisted_5 = { class: "_page-body" }

import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { Company, getCompanies } from "@/api/sso";


export default /*@__PURE__*/_defineComponent({
  __name: 'ApiUsage',
  setup(__props) {

const route = useRoute();

const checkRoute = (path: string) =>
  route.path === `/dashboard/api_usage${path}` ? "_page-tab_active" : "";

const selectedCompany = ref<string>("");
const companies = ref<Company[]>([]);

onBeforeMount(async () => {
  companies.value = await getCompanies();
  if (companies.value.length > 0) selectedCompany.value = companies.value[0].id;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "API usage overview", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCompany).value = $event)),
        class: "_input",
        name: "company",
        id: "company"
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("option", {
          value: "",
          selected: "",
          disabled: ""
        }, "Select company", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companies.value, (company) => {
          return (_openBlock(), _createElementBlock("option", {
            key: company.id,
            value: company.id
          }, _toDisplayString(company.name), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, selectedCompany.value]
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("nav", null, [
        _createVNode(_component_RouterLink, {
          class: _normalizeClass(checkRoute('/monthly_usage')),
          to: "/dashboard/api_usage/monthly_usage"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Monthly usage")
          ])),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_RouterLink, {
          class: _normalizeClass(checkRoute('/rate_limit')),
          to: "/dashboard/api_usage/rate_limit"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Rate limit ")
          ])),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(_component_RouterView, { "company-sso-id": selectedCompany.value }, null, 8, ["company-sso-id"])
    ])
  ]))
}
}

})