import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoBar',
  props: {
    type: {}
  },
  setup(__props: any) {

const props = __props;

const typeStyle = computed(() => {
  switch (props.type) {
    case "info":
      return { class: "_info", icon: "fa-circle-info" };
    case "warn":
      return { class: "_warn", icon: "fa-triangle-exclamation" };
    default:
      return { class: "_info", icon: "fa-circle-info" };
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`_infobar ${typeStyle.value.class}`)
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("i", {
        class: _normalizeClass(`fas fa-solid ${typeStyle.value.icon}`)
      }, null, 2)
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})