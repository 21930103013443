import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "menu-item-group" }
const _hoisted_2 = {
  href: "/docs/kb",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MpxIcon = _resolveComponent("MpxIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { collapsed: _ctx.collapsed }])
  }, [
    _withDirectives((_openBlock(), _createElementBlock("span", {
      class: "sidebar-toggle",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createVNode(_component_MpxIcon, { variant: 'chevron-right' })
    ])), [
      [_vShow, _ctx.collapsed],
      [
        _directive_tooltip,
        'Expand',
        void 0,
        { right: true }
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("span", {
      class: "sidebar-toggle",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createVNode(_component_MpxIcon, { variant: 'chevron-left' })
    ])), [
      [_vShow, !_ctx.collapsed],
      [
        _directive_tooltip,
        'Collapse',
        void 0,
        { right: true }
      ]
    ]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.flags.apiUsage)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/dashboard/api_usage/monthly_usage"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MpxIcon, {
                variant: "dashboard",
                "primary-color": "slate"
              }),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-icon" }, "API usage overview", -1))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, { to: "/dashboard/applications" }, {
        default: _withCtx(() => [
          _createVNode(_component_MpxIcon, {
            variant: "stock-available",
            "primary-color": "slate"
          }),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-icon" }, "Applications", -1))
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: _ctx.ROUTES.HMAC_VALIDATOR
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MpxIcon, {
            variant: "channel",
            "primary-color": "slate"
          }),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-icon" }, "HMAC Validator", -1))
        ]),
        _: 1
      }, 8, ["to"]),
      (_ctx.flags.activityLogs)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: "/dashboard/activity_logs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MpxIcon, {
                variant: "individual-goal",
                "primary-color": "slate"
              }),
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-icon" }, "Activity Logs", -1))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_MpxIcon, {
          variant: "file-doc",
          "primary-color": "slate"
        }),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-icon" }, "Documentation", -1))
      ])
    ])
  ], 2))
}