<template>
  <div class="select-container">
    <div class="select-content" @click="toggleDropdown">
      <span class="selected-option">{{ selectedCompanyName }}</span>
      <i v-if="isDropdownOpen" class="fa-solid fa-chevron-up sort-icon"></i>
      <i v-else class="fa-solid fa-chevron-down sort-icon"></i>
    </div>
    <div v-if="isDropdownOpen && companies.length > 0" class="dropdown-menu">
      <div
        v-for="company in companies"
        :key="company.ssoId"
        :value="`${company.ssoId}|${company.platforms}`"
        class="dropdown-item"
        @click="selectCompany(company)"
      >
        {{ company.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companies: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select Company",
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedCompany: null,
    };
  },
  computed: {
    selectedCompanyName() {
      return this.selectedCompany
        ? this.selectedCompany.name
        : this.placeholder;
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectCompany(company) {
      this.selectedCompany = company;
      this.isDropdownOpen = false;
      this.$emit("change", company);
    },
  },
};
</script>

<style scoped>
.sort-icon {
  margin-left: 5px;
}

.select-container {
  position: relative;
  width: 100%;
  min-width: 250px;
  max-width: 250px;
}

.select-content {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust the width as needed */
  display: block;
}

.dropdown-arrow {
  margin-left: 5px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words */
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
</style>
