import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "_dropdown" }
const _hoisted_2 = {
  key: 0,
  class: "fa-solid fa-chevron-up sort-icon"
}
const _hoisted_3 = {
  key: 1,
  class: "fa-solid fa-chevron-down sort-icon"
}
const _hoisted_4 = {
  key: 0,
  class: "_input _dropdown-menu"
}
const _hoisted_5 = { class: "_dropdown-header" }
const _hoisted_6 = ["id", "value", "checked", "onChange"]
const _hoisted_7 = ["for"]

import { computed, ref } from "vue";

export type Option = { id: string; text: string };


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownCheck',
  props: /*@__PURE__*/_mergeModels({
    options: {},
    optionsTitle: {},
    placeholder: {}
  }, {
    "modelValue": { default: [] },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const model = _useModel<string[]>(__props, "modelValue");

const showDropdown = ref<boolean>(false);

const placeholder = computed(() =>
  model.value.length === 0
    ? props.placeholder
    : model.value
        .map((v) => props.options.find(({ id }) => id == v).text)
        .join(", ")
);

const onCheck = (value: string) => {
  if (value == "") return;
  model.value = model.value.includes(value)
    ? model.value.filter((v) => v != value)
    : [...model.value, value];
};

const selectAll = () => {
  model.value = props.options.map(({ id }) => id);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "_input _dropdown-toggle",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showDropdown.value = !showDropdown.value))
    }, [
      _createTextVNode(_toDisplayString(placeholder.value) + " ", 1),
      (showDropdown.value)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : (_openBlock(), _createElementBlock("i", _hoisted_3))
    ]),
    (showDropdown.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(props.optionsTitle), 1),
            _createElementVNode("a", { onClick: selectAll }, "Select all")
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "_dropdown-item",
              key: option.id
            }, [
              _createElementVNode("input", {
                type: "checkbox",
                id: option.id,
                value: option.id,
                checked: model.value.find((v) => v === option.id) != undefined,
                onChange: ($event: any) => (onCheck(option.id))
              }, null, 40, _hoisted_6),
              _createElementVNode("label", {
                for: option.id
              }, _toDisplayString(option.text), 9, _hoisted_7)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})