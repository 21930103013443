type Response<T> = {
  data: T;
  meta?: unknown;
  links?: unknown;
};

const request = async <T, R>(
  method: string,
  url: string,
  payload: T,
  headers?: Record<string, string>
): Promise<R> => {
  const token = (document.querySelector("[name=csrf-token]") as HTMLMetaElement)
    ?.content;

  const newHeaders = {
    "X-CSRF-TOKEN": token,
    "Content-Type": "application/json",
    ...headers,
  };

  const init: RequestInit = {
    method,
    headers: newHeaders,
  };

  if (payload) init.body = JSON.stringify(payload);

  const response = await fetch(url, init);
  if (!response.ok)
    throw new Error(`HTTP error: request to ${url} return ${response.status}`);
  const json = await response.json();
  return (json as Response<R>).data;
};

export const GET = <T>(url: string, headers?: Record<string, string>) =>
  request<null, T>("GET", url, null, headers);
